// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-releases-index-js": () => import("./../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-templates-commissions-page-js": () => import("./../../src/templates/commissions-page.js" /* webpackChunkName: "component---src-templates-commissions-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-release-js": () => import("./../../src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */)
}

